<template>
	<main class="vcc-main">
		<router-link :to="{ name: 'AdminMaterials' }">← Назад</router-link><br><br>
		<new-material-form :material-type="type" :approoveMode="true" :item="currentItem" v-if="currentItem.id"/>
	</main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import NewMaterialForm from '../../../components/forms/NewMaterialForm.vue'
export default {
  components: { NewMaterialForm },
	name: 'MaterialData',
	props: {
		id: {
			type: [Number, String],
			required: true,
		},
		type: {
			type: String,
			required: true,
		}
	},
	computed: {
		...mapGetters([
			'getRla',
			'getMaterial',
			'getQuestion',
		]),
		currentItem() {
			switch (this.type) {
			case 'rla':
				return this.getRla
			case 'material':
				return this.getMaterial
			case 'faq':
				return this.getQuestion
			default:
				return []
			}
		}
	},
	methods: {
		...mapActions([
			'fetchRla',
			'fetchMaterial',
			'fetchQuestion',
		])
	},
	created() {
		switch (this.type) {
			case 'rla':
				this.fetchRla(this.id)
				break;
			case 'material':
				this.fetchMaterial(this.id)
				break;
			case 'faq':
				this.fetchQuestion(this.id)
				break;
			default:
				break;
		}
	}
}
</script>

<style>

</style>
